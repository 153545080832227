@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-enter {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  z-index: 1;
}

.fade-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease;
}

.fade-exit {
  transform: translateX(-100%);
}
.oppositeFade-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  z-index: 1;
}

.oppositeFade-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease;
}

.oppositeFade-exit {
  transform: translateX(100%);
}

.active {
  filter: invert(0.5) sepia(1) saturate(50) hue-rotate(175deg);
}

.active-red {
  filter: invert(0.5) sepia(1) saturate(60) hue-rotate(340deg);
}

.hideItem {
  display: none;
}

.pac-icon {
  display: none;
}

.pac-container {
  width: 100% !important;
  left: 0 !important;
  background: #fafafa;
  margin-top: 17px;
  box-shadow: none;
  border-top: none;
  padding-left: 12px;
  padding-right: 12px;
}

.pac-container:last-child:after {
  display: none !important;
}

.pac-item {
  padding: 10px 0px 10px 0px;
  font-family: 'GT Walsheim';
  font-size: 14px;
  border-top: none;
  border-bottom: 1px solid #e6e6e6;
}

.pac-item:nth-child(3) {
  border-bottom: none;
}

.pac-item:nth-child(4) {
  display: none;
}
.pac-item:nth-child(5) {
  display: none;
}

.pac-item:last-child:after {
  display: none !important;
}

.pac-item-query {
  font-family: 'GT Walsheim';
  font-size: 16px;
  padding-right: 5px;
}

.MuiPopover-root {
  z-index: 999999999 !important;
}

html {
  background-color: white;
}

a {
  color: black !important;
  text-decoration: none;
  font-family: 'GT Walsheim';
}

.errorCard {
  animation: myanim 1.5s 3;
}

@keyframes myanim {
  20% {
    border-color: #ff9999;
  }
}
