h5 {
  font-size: 16px;
  line-height: 1.2;
}

h6 {
  font-size: 14px;
  line-height: 1.2;
}
body {
  overscroll-behavior: none;
  user-select: none; // This disables it everywhere,
}
@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 100;
  src: url('../../src/assets/GT\ Walsheim/GT-Walsheim-Medium.eot');
  /* Safari, Android, iOS */
  /* IE9 Compat Modes */
  src: local('GT WalsheGT-Walsheim-Boldim'), local('GTWalsheim'),
    url('../../src/assets/GT\ Walsheim/GT-Walsheim-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../src/assets/GT\ Walsheim/GT-Walsheim-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../../src/assets/GT\ Walsheim/GT-Walsheim-Medium.ttf')
      format('truetype');
}
@font-face {
  font-family: 'GT Walsheim Regular';
  font-style: normal;
  font-weight: 100;
  src: url('../../src/assets/GT\ Walsheim/GT-Walsheim-Regular.eot');
  /* Safari, Android, iOS */
  /* IE9 Compat Modes */
  src: local('GT WalsheGT-Walsheim-Boldim'), local('GTWalsheim'),
    url('../../src/assets/GT\ Walsheim/GT-Walsheim-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../src/assets/GT\ Walsheim/GT-Walsheim-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../../src/assets/GT\ Walsheim/GT-Walsheim-Regular.ttf')
      format('truetype');
}
@font-face {
  font-family: 'GT Walsheim light';
  font-style: normal;
  font-weight: 100;
  src: url('../../src/assets/GT\ Walsheim/GT-Walsheim-Light.eot');
  /* Safari, Android, iOS */
  /* IE9 Compat Modes */
  src: local('GT WalsheGT-Walsheim-Boldim'), local('GTWalsheim'),
    url('../../src/assets/GT\ Walsheim/GT-Walsheim-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../src/assets/GT\ Walsheim/GT-Walsheim-Light.woff') format('woff'),
    /* Modern Browsers */ url('../../src/assets/GT\ Walsheim/GT-Walsheim-Light.ttf')
      format('truetype');
}
.post {
  opacity: 0.7;
}
.avatar {
  float: left;
  width: 52px;
  height: 52px;
  background-color: #ccc;
  border-radius: 100%;
  margin: 8px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-avatar 1.6s infinite linear;
}
.avatar-small {
  float: left;
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 100%;
  margin: 8px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-avatar 1.6s infinite linear;
}
.line {
  float: left;
  width: 200px;
  height: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}
.line-thick {
  float: left;
  width: 47%;
  height: 45px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 9px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}

.line-full {
  float: left;
  width: 97%;
  height: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}
.post .avatar + .line {
  margin-top: 11px;
  width: 100px;
}
.post .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 300px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%,
  100% {
    background-position: 208px;
  }
}

::-webkit-scrollbar {
  display: none;
}
