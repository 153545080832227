body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.fade-enter {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  z-index: 1;
}

.fade-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.fade-exit {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.oppositeFade-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  z-index: 1;
}

.oppositeFade-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.oppositeFade-exit {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.active {
  -webkit-filter: invert(0.5) sepia(1) saturate(50) hue-rotate(175deg);
          filter: invert(0.5) sepia(1) saturate(50) hue-rotate(175deg);
}

.active-red {
  -webkit-filter: invert(0.5) sepia(1) saturate(60) hue-rotate(340deg);
          filter: invert(0.5) sepia(1) saturate(60) hue-rotate(340deg);
}

.hideItem {
  display: none;
}

.pac-icon {
  display: none;
}

.pac-container {
  width: 100% !important;
  left: 0 !important;
  background: #fafafa;
  margin-top: 17px;
  box-shadow: none;
  border-top: none;
  padding-left: 12px;
  padding-right: 12px;
}

.pac-container:last-child:after {
  display: none !important;
}

.pac-item {
  padding: 10px 0px 10px 0px;
  font-family: 'GT Walsheim';
  font-size: 14px;
  border-top: none;
  border-bottom: 1px solid #e6e6e6;
}

.pac-item:nth-child(3) {
  border-bottom: none;
}

.pac-item:nth-child(4) {
  display: none;
}
.pac-item:nth-child(5) {
  display: none;
}

.pac-item:last-child:after {
  display: none !important;
}

.pac-item-query {
  font-family: 'GT Walsheim';
  font-size: 16px;
  padding-right: 5px;
}

.MuiPopover-root {
  z-index: 999999999 !important;
}

html {
  background-color: white;
}

a {
  color: black !important;
  text-decoration: none;
  font-family: 'GT Walsheim';
}

.errorCard {
  -webkit-animation: myanim 1.5s 3;
          animation: myanim 1.5s 3;
}

@-webkit-keyframes myanim {
  20% {
    border-color: #ff9999;
  }
}

@keyframes myanim {
  20% {
    border-color: #ff9999;
  }
}

.spinner {
  display: inline-block;
  width: 120px;
  height: 120px;
  position: fixed;
  top: 40%;
  left: 40%;
}

.spinner:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 15px solid transparent;
  border-top-color: red;
  border-right-color: red;
  border-radius: 50%;
  -webkit-transform: rotate(-75deg);
          transform: rotate(-75deg);
  -webkit-animation: rotate 1s infinite ease-out;
          animation: rotate 1s infinite ease-out;
}

.spinner:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 15px solid transparent;
  border-bottom-color: #1f0d33;
  border-left-color: #1f0d33;
  border-radius: 50%;
  -webkit-transform: rotate(-75deg);
          transform: rotate(-75deg);
  -webkit-animation: rotate 2s infinite ease-out;
          animation: rotate 2s infinite ease-out;
}

.image {
  -webkit-animation: rotate 1s infinite linear;
          animation: rotate 1s infinite linear;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  100% {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  100% {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
}
h5 {
  font-size: 16px;
  line-height: 1.2; }

h6 {
  font-size: 14px;
  line-height: 1.2; }

body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/GT-Walsheim-Medium.feca5039.eot);
  /* Safari, Android, iOS */
  /* IE9 Compat Modes */
  src: local("GT WalsheGT-Walsheim-Boldim"), local("GTWalsheim"), url(/static/media/GT-Walsheim-Medium.feca5039.eot?#iefix) format("embedded-opentype"), url(/static/media/GT-Walsheim-Medium.2c3ff531.woff) format("woff"), url(/static/media/GT-Walsheim-Medium.abbebf89.ttf) format("truetype"); }

@font-face {
  font-family: 'GT Walsheim Regular';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/GT-Walsheim-Regular.7b7a7736.eot);
  /* Safari, Android, iOS */
  /* IE9 Compat Modes */
  src: local("GT WalsheGT-Walsheim-Boldim"), local("GTWalsheim"), url(/static/media/GT-Walsheim-Regular.7b7a7736.eot?#iefix) format("embedded-opentype"), url(/static/media/GT-Walsheim-Regular.282c1d9d.woff) format("woff"), url(/static/media/GT-Walsheim-Regular.a78cebcc.ttf) format("truetype"); }

@font-face {
  font-family: 'GT Walsheim light';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/GT-Walsheim-Light.ef891034.eot);
  /* Safari, Android, iOS */
  /* IE9 Compat Modes */
  src: local("GT WalsheGT-Walsheim-Boldim"), local("GTWalsheim"), url(/static/media/GT-Walsheim-Light.ef891034.eot?#iefix) format("embedded-opentype"), url(/static/media/GT-Walsheim-Light.3a2e60c3.woff) format("woff"), url(/static/media/GT-Walsheim-Light.cc8d53ee.ttf) format("truetype"); }

.post {
  opacity: 0.7; }

.avatar {
  float: left;
  width: 52px;
  height: 52px;
  background-color: #ccc;
  border-radius: 100%;
  margin: 8px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  -webkit-animation: shine-avatar 1.6s infinite linear;
          animation: shine-avatar 1.6s infinite linear; }

.avatar-small {
  float: left;
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 100%;
  margin: 8px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  -webkit-animation: shine-avatar 1.6s infinite linear;
          animation: shine-avatar 1.6s infinite linear; }

.line {
  float: left;
  width: 200px;
  height: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 1.6s infinite linear;
          animation: shine-lines 1.6s infinite linear; }

.line-thick {
  float: left;
  width: 47%;
  height: 45px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 9px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 1.6s infinite linear;
          animation: shine-lines 1.6s infinite linear; }

.line-full {
  float: left;
  width: 97%;
  height: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 1.6s infinite linear;
          animation: shine-lines 1.6s infinite linear; }

.post .avatar + .line {
  margin-top: 11px;
  width: 100px; }

.post .line ~ .line {
  background-color: #ddd; }

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px; }
  40%,
  100% {
    background-position: 300px; } }

@keyframes shine-lines {
  0% {
    background-position: -100px; }
  40%,
  100% {
    background-position: 300px; } }

@-webkit-keyframes shine-avatar {
  0% {
    background-position: -32px; }
  40%,
  100% {
    background-position: 208px; } }

@keyframes shine-avatar {
  0% {
    background-position: -32px; }
  40%,
  100% {
    background-position: 208px; } }

::-webkit-scrollbar {
  display: none; }

